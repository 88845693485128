import React from 'react'
import styles from './header_back.module.scss'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles(theme => ({
	root: {
	  flexGrow: 1,
	},
	paper: {
	  padding: theme.spacing(2),
	  textAlign: 'center',
	  color: theme.palette.text.secondary,
	},
  }));
  

const HeaderBack = ({ title, description, image }) => {
	const classes = useStyles();
	return(
		<div className={styles.container} style={{backgroundImage: `url(${image})`}}>
			{/* <h1> {title} </h1>
			<p> {description} </p> */}
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<h1> {title} </h1>
				</Grid>
				<Hidden mdDown>
				<Grid item xs={12}
					display={{ xs: 'none', md: 'none', lg: 'block' }}>
					<p> {description} </p>
				</Grid>
				</Hidden>
			</Grid>
		</div>
	)
}

export default HeaderBack

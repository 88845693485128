import React, {useState, useEffect} from "react"
import { Link, navigate } from "gatsby"
import styles from './header.module.scss'
import { Typography, Divider, Dialog, DialogTitle, DialogContent, DialogContentText, Button, Popper, Paper, ClickAwayListener, MenuItem, MenuList, Fade} from '@material-ui/core';
import {
  Col, 
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';
  import { useSelector, useDispatch } from 'react-redux'
  import config from '../../config'
  import { IoIosArrowDown } from "react-icons/io";
  import 'bootstrap/dist/css/bootstrap.min.css';


const TimHeader = () =>{
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch()
	const [, forceUpdate] = useState();
	const isLogged = useSelector(state => state.user.isLogged)
	const isConfirm = useSelector(state => state.user.isConfirm)
	const promos = useSelector(state => state.user.promos)
	const userData = useSelector(state => state.user.userData)
	const [openUserMenu, setOpenUserMenu] = useState(false)
	const [openPromo, setOpenPromo] = useState(false);
	const anchorRef = React.useRef(null);
	const delete_cookie = (name) => {
	    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	};

	const handleLogout = ()=>{
		dispatch({type: 'user/SET_LOGGED', data: false})
		dispatch({type: 'user/SET_USER_DATA', data: {}})
		delete_cookie('jwtoken');
		navigate('/')
	}

	const handleOpenPromo = () => {
		setOpenUserMenu(!openUserMenu)
		setOpenPromo(true)
	}

	const getPromosCode = async(user_id) => {
	    try{
	        const response = await fetch(`${config.server}/getCodesByUser/${userData.id}`,{
	            method: 'GET',
	            headers: {
	                'Content-Type': 'application/json'
	            }
	        })
	        const promosCodes = await response.json();
			dispatch({type: 'user/SET_PROMOS_CODE', data: promosCodes})
	    }catch(e){
	        console.log(e)
	    }
	}

	useEffect(()=>{
		if(isLogged){
			getPromosCode();
		}
	},[isLogged])

  const toggle = () => setIsOpen(!isOpen);
  
  return (
    <div>
      <Navbar color="white" light expand="lg">
        <NavbarBrand href="/">
          <img alt="logo_cowork" src={require("../../images/logo.svg")} />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem
              style={{
                paddingLeft: "3rem",
                paddingTop: "0.5rem"
              }}
            >
              <Link className="text-dark" to="/">
                Inicio
              </Link>
            </NavItem>
            <NavItem
              style={{
                paddingLeft: "3rem",
                paddingTop: "0.5rem"
              }}
            >
              <Link className="text-dark" to="/filter">
                Espacios
              </Link>
            </NavItem>
            <NavItem
              style={{
                paddingLeft: "3rem",
                paddingTop: "0.5rem"
              }}
            >
              <Link className="text-dark" to="/about">
                Sobre Cowork
              </Link>
            </NavItem>
            <NavItem
              style={{
                paddingLeft: "3rem",
                paddingTop: "0.5rem"
              }}
            >
              <Link className="text-dark" to="/contact">
                Contáctanos
              </Link>
            </NavItem>
            {isLogged ? (
              <>
              <NavItem
                style={{
                  paddingLeft: "3rem",
                  paddingTop: "0.5rem"
                }}
              >
                <Dialog
                  open={openPromo}
                  className={styles.dialogPromo}
                  onClose={() => setOpenPromo(!openPromo)}
                >
                  <DialogTitle
                    style={{
                      textAlign: "center",
                      paddingBottom: "0 !important",
                    }}
                  >
                    {"Bonos de promocion"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText align="center" variant="body2">
                      Estos son bonos de promocion, al usar un bono a la hora de
                      realizar una reserva se te hara un descuento de cierto
                      porcentaje.
                    </DialogContentText>
                    <Divider
                      style={{
                        margin: "1em 0",
                        marginTop: "0.5em",
                      }}
                    />
                    <div className={styles.promosContainer}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <DialogContentText
                          style={{ width: "50%" }}
                          align="center"
                          variant="p"
                        >
                          Codigo
                        </DialogContentText>
                        <DialogContentText
                          style={{ width: "50%" }}
                          align="center"
                          variant="p"
                        >
                          Descuento
                        </DialogContentText>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          //alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        {promos != false
                          ? promos.map((el, idx) => {
                              if (new Date(el.expired_date) > new Date()) {
                                return (
                                  <div
                                    style={{ width: "100%" }}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                      alignItems: "center",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <DialogContentText
                                      style={{ width: "50%" }}
                                      color="#000"
                                      align="center"
                                      variant="h6"
                                    >
                                      {el.code.toUpperCase()}
                                    </DialogContentText>
                                    <DialogContentText
                                      style={{ width: "50%" }}
                                      color="#000"
                                      align="center"
                                      variant="h6"
                                    >
                                      -{el.percent}%
                                    </DialogContentText>
                                  </div>
                                )
                              }
                            })
                          : null}
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    /* alignItems: "center", */
                    flexDirection: "row",
                  }}
                >
                  <p
                    style={{
                      margin: "0",
                      marginRight: "1em",
                    }}
                  >
                    ¡Hola {userData.name}!
                  </p>
                  <img
                    alt="profile_photo"
                    src={require("./../../images/icon_profile.svg")}
                    style={{
                      width: "50px",
                      alignSelf: "center",
                      /* margin: "0", */
                    }}
                  />
                  <div
                    style={{
                      marginLeft: "1em",
                    }}
                    ref={anchorRef}
                    onClick={() => setOpenUserMenu(!openUserMenu)}
                  >
                    <IoIosArrowDown size={20} />
                  </div>
                  <Popper
                    style={{
                      marginTop: "1em !important",
                    }}
                    open={openUserMenu}
                    anchorEl={anchorRef.current}
                    transition
                  >
                    <Fade in={true}>
                      <Paper>
                        <ClickAwayListener
                          onClickAway={() => setOpenUserMenu(false)}
                        >
                          <MenuList>
                            {/*<MenuItem className={styles.menuStyle} onClick={()=> setOpenUserMenu(!openUserMenu)}>Mi perfil</MenuItem>*/}
                            <MenuItem
                              style={{
                                fontFamily: "Barlow",
                                fontWeight: "500",
                              }}
                              onClick={handleOpenPromo}
                            >
                              Bonos
                            </MenuItem>
                            <MenuItem
                              style={{
                                fontFamily: "Barlow",
                                fontWeight: "500",
                              }}
                              onClick={handleLogout}
                            >
                              Cerrar sesion
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Fade>
                  </Popper>
                </div>
                </NavItem>
              </>
            ) : (
              <div style = {{marginLeft: "3rem", display: "flex"}}>
                <NavItem >
                  <Link to="/login" className={styles.linking}>
                    <Button
                      variant="outlined"
                      style={{
                        textTransform: "capitalize",
                        width: "5.875rem",
                        borderRadius: "0.7em",
                        borderWidth: "0.125rem",
                        borderColor: "#CD0D14",
                        color: "#CD0D14",
                        fontWeight: "600",
                        fontSize: "1rem",
                        marginRight: "0.875rem",
                        fontFamily: "Barlow",
                      }}
                    >
                      Ingresar
                    </Button>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/signup" className={styles.linking}>
                    <Button
                      variant="contained"
                      style={{
                        textTransform: "capitalize",
                        width: "9rem",
                        borderRadius: "0.7em",
                        borderWidth: "0.125rem",
                        borderColor: "#DE892A",
                        color: "#fff",
                        backgroundColor: "#DE892A",
                        fontWeight: "600",
                        fontSize: "1rem",
                        textTransform: "uppercase",
                        fontFamily: "Barlow",
                      }}
                    >
                      Únete
                    </Button>
                  </Link>
                </NavItem>
               
              </div>
            )}
          </Nav>
          {/* <Nav className="ml-auto" navbar
		  style = {{marginBottom: "2rem"}}>
          {
					isLogged ? (
						<>
							<Dialog
								open={openPromo}
								className={styles.dialogPromo}
								onClose={()=>setOpenPromo(!openPromo)}
							>
								<DialogTitle style = {{
                  textAlign: "center",
                  paddingBottom: "0 !important"
                }}>{"Bonos de promocion"}</DialogTitle>
								<DialogContent>
									<DialogContentText align="center" variant="body2">
										Estos son bonos de promocion, al usar un bono a la hora de realizar una reserva
										se te hara un descuento de cierto porcentaje.
									</DialogContentText>
									<Divider style = {{
                    margin: "1em 0",
                    marginTop: "0.5em"
                  }}/>
									<div className={styles.promosContainer}>
										<div style = {{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      flexDirection: "row"
                    }}>
											<DialogContentText style={{width:'50%'}} align="center" variant="p">
												Codigo
											</DialogContentText>
											<DialogContentText style={{width:'50%'}} align="center" variant="p">
												Descuento
											</DialogContentText>
										</div>
										<div style ={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      flexDirection: "column"
                    }}>
											{
												promos != false ? (
													promos.map((el,idx)=>{
														if(new Date(el.expired_date) > new Date()){
															return(
																<div style={{width:'100%'}} style = {{
                                  display: "flex",
				                          justifyContent: "space-around",
				                          alignItems: "center",
				                          flexDirection: "row"
                                }}>
																	<DialogContentText style={{width:'50%'}} color="#000" align="center" variant="h6">
																		{el.code.toUpperCase()}
																	</DialogContentText>
																	<DialogContentText style={{width:'50%'}} color="#000" align="center" variant="h6">
																		-{el.percent}%
																	</DialogContentText>
																</div>
															)
														}
													})
												):(
													null
												)

											}
										</div>
									</div>

								</DialogContent>
							</Dialog>
							<div style = {{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row"
              }}>
								<p style = {{
                  margin: "0",
                  marginRight: "1em"
                }}>¡Hola {userData.name}!</p>
								<img alt="profile_photo" src={require('./../../images/icon_profile.svg')}
                style = {{
                  width: "50px",
			            alignSelf: "center",
			            margin: "0"
                }}/>
								<div style ={{
                  marginLeft: "1em"
                }} ref={anchorRef} onClick={()=> setOpenUserMenu(!openUserMenu)}>
									<IoIosArrowDown size={20}/>
								</div>
								<Popper style = {{
                  marginTop: "1em !important"
                }} open={openUserMenu} anchorEl={anchorRef.current} transition>
									<Fade in={true}>
										<Paper>
											<ClickAwayListener onClickAway={()=> setOpenUserMenu(false)}>
												<MenuList>
													<MenuItem style = {{
                            fontFamily: 'Barlow',
                            fontWeight: "500"
                          }} onClick={handleOpenPromo}>Bonos</MenuItem>
													<MenuItem style = {{
                            fontFamily: 'Barlow',
                            fontWeight: "500"
                          }} onClick={handleLogout}>Cerrar sesion</MenuItem>
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Fade>
								</Popper>
							</div>
						</>
					):(
						<div className={styles.buttonNav}>
							<Link to="/login" className={styles.linking}>
								<Button variant="outlined"
									style = {{
									textTransform: "capitalize",
									width: "5.875rem",
									borderRadius: "0.7em",
									borderWidth: "0.125rem",
									borderColor: "#CD0D14",
									color: "#CD0D14",
									fontWeight: "600",
									fontSize: "1rem",
									marginRight: "0.875rem",
									fontFamily: 'Barlow'
									}}>
									Ingresar
								</Button>
							</Link>
							<Link to="/signup" className={styles.linking}>
								<Button variant="contained" style = {{
									marginLeft: "0.875rem",
									textTransform: "capitalize",
									width: "9rem",
									borderRadius: "0.7em",
									borderWidth: "0.125rem",
									borderColor: "#DE892A",
									color: "#fff",
									backgroundColor: "#DE892A",
									fontWeight: "600",
									fontSize: "1rem",
									textTransform: "uppercase",
									fontFamily: 'Barlow'
									}}>
									Únete
								</Button>
							</Link>
						</div>
					)
				}
           
           
          </Nav> */}
        </Collapse>
      </Navbar>
      {isLogged && !isConfirm ? (
        <div className={styles.confirmEmail}>
          No has confirmado tu email, recuerda que asi no podras realizar
          ninguna reserva, por favor revisa tu correo, si ya lo hiciste recarga
          la pagina.
        </div>
      ) : null}
    </div>
  )
}

export default TimHeader;
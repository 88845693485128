/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql} from "gatsby"
import Header from "../Header"
import TimHeader from '../TimHeader'
import Footer from "../Footer"
import store from './../../redux/store'

import "./layout.css"
import styles from './layout.module.scss'

const Layout = ({children}) => {
	const data = useStaticQuery(graphql `
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

	return (<div className={styles.container}>
		<div className={styles.content}>
      {/* <SectionNavbars /> */}
      <TimHeader/>
			{/* <Header/>  */}{children}
		</div>
		<Footer/>
	</div>)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired
}

export default Layout

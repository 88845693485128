import React from 'react'
import {Link} from 'gatsby'
import styles from './footer.module.scss'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
	root: {
	  flexGrow: 1,
	},
	subtitle:{
		color: "#23263E",
		fontWeight: "600",
		marginBottom: "1em"
	},
	p:{
		color: "#7F8299"
	},
	link:{
		textDecoration: "none",
		color: "#7F8299",
		marginBottom: "1em"
	},
	longDescription:{
			display: "flex",
			color: "#7F8299",
			justifyContent: "center",
			alignItems: "flex-start",
			flexDirection: "column",
			width: "70%",
			marginRight: "4em",
			marginLeft: "4rem"
		},
		socialContainer:{
			position: "absolute",
			top: "2em",
			right: "3em",
		},
		socialUl:{
			display: "flex",
			listStyle: "none",
		},
		socialLi:{
			margin: "0 0.5em"
		},
		socialLink:{
			margin: "0.4rem"
		}
  }));

const Footer = () => {
	const classes = useStyles();

	return(
		<div className={styles.container}>
			<div className={classes.root}>
				<Grid container spacing={3}>
				<Grid item xs={6} md = {6}>
					<img src={require('./../../images/logo.svg')}
					style = {{marginLeft: "3rem"}}/>
					
					</Grid>
					<Grid item xs={6} md = {6}
					style = {{display: "flex", alignItems: "flex-end", 
					paddingLeft: "1.6rem", paddingBottom: "2rem"}}>
					
					<a className = {classes.socialLink} href = "https://www.facebook.com/CoworkEntretodos" 
								target = "_blank"><img width="20px" 
								src={require('./../../images/fa.svg')}/></a>
								<a className = {classes.socialLink} href = "https://www.instagram.com/coworkentretodos/"
							target = "_blank"><img width="20px" 
							src={require('./../../images/ig.svg')}/></a>
					
					</Grid>
					{/* <Grid item xs={12} md = {6}>
					<div className={classes.socialContainer}>
						<ul className = {classes.socialUl}>
							<li className = {classes.socialLi}><a href = "https://www.facebook.com/CoworkEntretodos" 
								target = "_blank"><img width="20px" 
								src={require('./../../images/fa.svg')}/></a></li>
							<li className = {classes.socialLi}><a href = "https://www.instagram.com/coworkentretodos/"
							target = "_blank"><img width="20px" 
							src={require('./../../images/ig.svg')}/></a></li>
						</ul>
					</div>
					</Grid> */}
					<Grid item xs={12} md = {6}>
						<p className={classes.longDescription} style={{textAlign: "justify"}}>
							COWORK ENTRE TODOS es un centro de Co-Working ubicado en el centro financiero de Barranquilla. 
							Cowork Entre Todos está dedicado a la implementación de espacios compartidos, salas de junta &
							oficinas privadas para el uso de los clientes.
						</p>
					</Grid>
					<Grid item xs={6} md = {3} style = {{paddingLeft: "2rem"}}>
					<div className={styles.ourDescription}>
						<p className={classes.subtitle}>Cowork</p>
						<ul>
							<Link to="/about" className={classes.link}><li>Acerca De</li></Link>
							<Link to="/contact" className={classes.link}><li>Contacto</li></Link>
							<Link to= "/signup" className={classes.link}><li>Unirse</li></Link>
						</ul>	
					</div>
					</Grid>
					<Grid item xs={6} md = {3}>
					<div className={styles.languageDescription}>
						<p className={classes.subtitle}>Contacto</p>
						<p className={classes.p}> +57(605)343-2733</p>
						<p className={classes.p}> +57(322)227-2726</p>
						<p className={classes.p}> Carrera 53 #72-136 oficina 3C</p>
					</div>
					</Grid>
				</Grid>
			</div>
				<div className={styles.secondaryFooterContainer}>
					<Grid container spacing={3}>
						<Grid item md = {6} xs={12}>
						<p> Copyright © 2020 Cowork entre todos  All rights reserved </p>
						</Grid>
						<Grid item md = {6} xs={12}>
							<ul>
								<a><li>Privacidad</li></a>
								<a><li>Terminos</li></a>
								<a><li>Cookies</li></a>
								<a><li>FAQ</li></a>
							</ul>
						</Grid>
					</Grid>
				</div>
			
			{/* <div className={styles.socialContainer}>
				<ul>
					<li><a href = "https://www.facebook.com/CoworkEntretodos" 
						target = "_blank"><img width="20px" 
						src={require('./../../images/fa.svg')}/></a></li>
					<li><a href = "https://www.instagram.com/coworkentretodos/"
					 target = "_blank"><img width="20px" 
					 src={require('./../../images/ig.svg')}/></a></li>
				</ul>
			</div> */}
		</div>
	)
}

export default Footer;
